import React, { useEffect, useRef, useState } from "react"
import { getAdminLocation, getCateringOrderStatus, getCateringWithFilter, updateCateringOrderStatus, updateResponsible, } from "../../services/ApiServices"
import { changeDateValue, changeLocationValue, changeStatusValue, changeUserValue } from "../../store/slices/CateringOrderInputStatesSlice"
import { changeAllCateringOrderStatusValue, changeAllLocationValue, } from "../../store/slices/APIResponseSlice"
import { handleError, timeInterval } from "../../const"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ClipLoader } from "react-spinners"
import ReactDatePicker from "react-datepicker"
import Loader from "../Loader/Loader"
import toast from "react-hot-toast"
import moment from "moment/moment"
import Select from "react-select"
import "react-datepicker/dist/react-datepicker.css"
import "./Order.css"

// ==== Imported Image ====
import iconUser from "../../assets/img/icon-user.svg"
import iconPin from "../../assets/img/icon-pin.svg"
import iconUsers from "../../assets/img/icon-users.svg"
import iconClock from "../../assets/img/icon-clock.svg"
import iconClose from "../../assets/img/icon-close.svg"
import iconEdit from "../../assets/img/icon-edit.svg"
import iconSave from "../../assets/img/icon-save.svg"
import iconOrderNumber from "../../assets/img/icon-order-number.svg"

export default function Order() {
  const selectedCanteenUsers = useSelector((state) => state.InputStates.inputUser)
  const selectedLocation = useSelector((state) => state.InputStates.inputLocation)
  const selectedDate = useSelector((state) => state.InputStates.inputDate)
  const selectedOrderStatus = useSelector((state) => state.InputStates.inputStatus)

  const AllLocations = useSelector((state) => state?.APIResponse?.AllLocations)
  const AllCanteenUsers = useSelector((state) => state?.APIResponse?.AllCanteenUsers)
  const AllCateringOrderStatus = useSelector((state) => state?.APIResponse?.AllCateringOrderStatus)

  // =============== Local States ===============
  const [isLoading, setIsLoading] = useState(false)
  const [filterData, setFilterData] = useState([])
  const [deliverUpdateStatus, setDeliverUpdateStatus] = useState(false)
  const [deliverUpdateStatusId, setDeliverUpdateStatusId] = useState(0)
  const [updateCanteenUsers, setUpdateCanteenUsers] = useState([])
  const [updateCanteenUsersDefaultValue, setUpdateCanteenUsersDefaultValue] = useState([])
  const [isCardUpdating, setIsCardUpdating] = useState(false)
  const [isStatusUpdating, setIsStatusUpdating] = useState({ status: false, Id: "" })

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const tempRef = useRef()
  const userRef = useRef()
  const locationRef = useRef()

  const setInputValueToRedux = () => {
    dispatch(changeUserValue(selectedCanteenUsers))
    dispatch(changeLocationValue(selectedLocation))
    dispatch(changeDateValue(selectedDate))
    dispatch(changeStatusValue(selectedOrderStatus))
  }

  const GetAdminLocation = async () => {
    if (AllLocations.length === 0) {
      await getAdminLocation().then((response) => {
        dispatch(changeAllLocationValue(response))
      }).catch((error) => {
        dispatch(changeAllLocationValue([]))
        toast.error(error)
      })
    }
  }

  const GetCateringOrderStatus = async () => {
    if (AllCateringOrderStatus.length === 0) {
      await getCateringOrderStatus().then((response) => {
        dispatch(changeAllCateringOrderStatusValue(response))
      }).catch((error) => {
        dispatch(changeAllCateringOrderStatusValue([]))
        toast.error(error)
      })
    }

    return
  }

  const GetFilteredData = async () => {
    // setIsLoading(true)
    await getCateringWithFilter(
      selectedLocation.map((d) => d?.Id), selectedOrderStatus.map((d) => d?.Id), selectedDate, selectedDate,
      selectedCanteenUsers?.value ? selectedCanteenUsers?.value : ""
    ).then((response) => {
      if (typeof response === "object" && response?.length >= 0) {
        setFilterData(response?.sort((a, b) => moment(a?.Meeting?.Start).format("HHmm") - moment(b?.Meeting?.Start).format("HHmm")))
      } else {
        setFilterData([])
      }
      setIsCardUpdating(false)
      setDeliverUpdateStatus(false)
      setDeliverUpdateStatusId(0)
    }).catch((error) => {
      setFilterData([])
      toast.error(error)
    }).finally(() => {
      setIsLoading(false)
    })

    return
  }

  const UpdateResponsible = async (id) => {
    try {
      setIsCardUpdating(true)
      const response = await updateResponsible(id, updateCanteenUsers)
      setUpdateCanteenUsers([])
      setUpdateCanteenUsersDefaultValue([])
      if (!response) {
        GetFilteredData()
        toast.success("Delivered by Updated Successfully.")
      } else {
        toast.error("Error while updating data!")
      }
    } catch (error) {
      toast.error(error)
    }
  }

  const UpdateCateringOrderStatus = async (data, statusId, i) => {
    if (data?.Id && statusId) {
      setIsStatusUpdating({ status: true, Id: i })
      const response = await updateCateringOrderStatus(data?.Id, statusId)
      await GetFilteredData()
      if (!response) {
        toast.success("Order Status Updated Successfully.")
      } else {
        handleError(response)
      }
      setIsStatusUpdating({ status: false, Id: "" })
    }

    return
  }

  useEffect(() => {
    let subscribed = true

    setIsLoading(true)
    getCateringWithFilter(
      selectedLocation?.map((d) => d?.Id), selectedOrderStatus?.map((d) => d?.Id), selectedDate, selectedDate,
      selectedCanteenUsers?.value ? selectedCanteenUsers?.value : ""
    ).then((response) => {
      if (subscribed) {
        if (typeof response === "object" && response?.length > 0) {
          setFilterData(response?.sort((a, b) => moment(a?.Meeting?.Start).format("HHmm") - moment(b?.Meeting?.Start).format("HHmm")))
        } else {
          setFilterData([])
        }
      }
    }).catch((error) => {
      if (subscribed) {
        setFilterData([])
        handleError(error)
      }
    }).finally(() => {
      setIsCardUpdating(false)
      setDeliverUpdateStatus(false)
      setDeliverUpdateStatusId(0)
      setIsLoading(false)
    })

    const interval = setInterval(() => {
      GetFilteredData()
    }, timeInterval)

    return () => {
      subscribed = false
      clearInterval(interval)
    }
  }, [selectedDate, selectedLocation, selectedOrderStatus, selectedCanteenUsers])

  useEffect(() => {
    GetAdminLocation()
    GetCateringOrderStatus()
  }, [])

  return (
    <>
      {isLoading && <Loader />}

      <div className="pagetitle mb-5">
        <h3>Welcome to</h3>
        <h1 ref={tempRef}>Canteen Management</h1>
      </div>

      <section className="section order-section mb-4">
        <div className="inputSec-icon mb-3">
          <Select
            ref={userRef} openMenuOnFocus={true}
            value={[selectedCanteenUsers?.value ? selectedCanteenUsers : { value: "", label: "Select User" }]}
            onChange={(e) => dispatch(changeUserValue(e))}
            options={AllCanteenUsers?.length > 0
              ? [{ value: "", label: "Select User" }, ...AllCanteenUsers?.map((user) => ({ value: user?.Email, label: user?.Name }))]
              : [{ value: "", label: "Select User" }]
            }
            className="form-control p-0" placeholder="Select User"
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                border: "none",
                boxShadow: "none",
                padding: "8px 10px",
                borderRadius: 8,
              }),
            }}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null
            }}
          />
          <span onClick={() => userRef.current?.focus()}>
            <img alt="icon-user" src={iconUser} />
          </span>
        </div>

        <div className="inputSec-icon mb-3">
          <Select
            ref={locationRef} openMenuOnFocus={true} isMulti
            className="form-control p-0" placeholder="Select Location"
            value={selectedLocation?.map((location) => ({ value: location?.Id, label: location?.Name }))}
            onChange={(e) => dispatch(changeLocationValue(e?.map((d) => ({ Id: d.value, Name: d?.label }))))}
            options={AllLocations?.length > 0
              ? AllLocations?.map((location) => ({ value: location?.Id, label: location?.Name }))
              : []
            }
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                border: "none",
                boxShadow: "none",
                padding: "8px 10px",
                borderRadius: 8,
              }),
            }}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null
            }}
          />
          <span onClick={() => locationRef.current.focus()}>
            <img alt="icon-pin" src={iconPin} />
          </span>
        </div>

        <div className="row mb-4">
          <div className="col-6">
            <div className="inputSec-icon">
              <ReactDatePicker
                type="selectedDate"
                className="form-control"
                placeholder="DD/MM/YYYY"
                dateFormat="dd.MM.yyyy"
                calendarStartDay={1}
                selected={moment(selectedDate)._d}
                onChange={(date) => date && dispatch(changeDateValue(moment(date).format("YYYY-MM-DD")))}
              />
            </div>
          </div>

          <div className="col-6">
            <Select
              className="form-control p-0"
              isMulti openMenuOnFocus={true}
              placeholder="Select Status"
              value={selectedOrderStatus?.map((status) => ({ value: status?.Id, label: status?.Name }))}
              onChange={(e) => dispatch(changeStatusValue(e?.map((d) => ({ Id: d?.value, Name: d?.label }))))}
              options={AllCateringOrderStatus?.map((status) => ({ value: status?.Id, label: status?.Name }))}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  border: "none",
                  boxShadow: "none",
                  padding: "8px 10px",
                  borderRadius: 8,
                }),
              }}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null
              }}
            />
          </div>
        </div>

        {/* ================ Order Cards Section ================ */}
        {filterData && filterData?.length > 0 && (
          filterData?.map((data, i) =>
            <div className="card position-relative" key={i}>
              {((isCardUpdating && deliverUpdateStatus && deliverUpdateStatusId === i + 1) || (isStatusUpdating?.status && isStatusUpdating?.Id === i)) && (
                <div className="card-loader-wrapper">
                  <ClipLoader
                    className="card-loader"
                    loading={isCardUpdating || isStatusUpdating?.status}
                    size={50} color="blue"
                  />
                </div>
              )}

              <div className="edit-details">
                <h2
                  className="card-title"
                  onClick={() => {
                    setInputValueToRedux()
                    navigate({ pathname: `/order-details/${data.Id}`, })
                  }}
                >
                  {data?.Meeting?.Id ? data?.Meeting?.Title : "A Pickup Order"}
                </h2>
                {data?.Meeting !== null && data?.Meeting?.Id ? (
                  <button
                    type="button"
                    className="btn btn-secondary btn-icon icon-edit-details"
                    onClick={() => {
                      setDeliverUpdateStatus(!deliverUpdateStatus)
                      setDeliverUpdateStatusId(deliverUpdateStatusId === 0 ? i + 1 : 0)
                      setUpdateCanteenUsersDefaultValue(
                        deliverUpdateStatusId === 0 && data?.CanteenUsers != null
                          ? data?.CanteenUsers?.map((d) => ({
                            value: `${d?.Id}`,
                            label: `${d?.Name}`,
                          }))
                          : []
                      )
                      setUpdateCanteenUsers(data?.CanteenUsers ? data?.CanteenUsers?.map((d) => d?.Id) : [])
                    }}
                  >
                    <img alt="icon-edit" src={deliverUpdateStatus && deliverUpdateStatusId === i + 1 ? iconClose : iconEdit} />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-secondary btn-icon icon-edit-details"
                    onClick={() => {
                      setDeliverUpdateStatus(!deliverUpdateStatus)
                      setDeliverUpdateStatusId(deliverUpdateStatusId === 0 ? i + 1 : 0)
                      setUpdateCanteenUsersDefaultValue(
                        deliverUpdateStatusId === 0 && data?.CanteenUsers != null
                          ? data?.CanteenUsers?.map((d) => ({
                            value: `${d?.Id}`,
                            label: `${d?.Name}`,
                          }))
                          : []
                      )
                      setUpdateCanteenUsers(data?.CanteenUsers ? data?.CanteenUsers?.map((d) => d?.Id) : [])
                    }}
                  >
                    <img alt="icon-edit" src={deliverUpdateStatus && deliverUpdateStatusId === i + 1 ? iconClose : iconEdit} />
                  </button>
                )}
              </div>

              <div className="card-content">
                <div className="row rowMinus list">
                  <div className="col-6 txt-md d-flex ">
                    <span className="icon-sm"><img src={iconPin} alt="icon-pin" /></span>
                    <div className="d-flex align-items-center">
                      {(data?.Meeting !== null && data?.Meeting?.MeetingRooms.length > 0 && data?.Meeting?.Id) ?
                        <span className="fw-normal">
                          {data?.Meeting && data?.Meeting?.MeetingRooms[0] && data?.Meeting?.MeetingRooms[0]?.Location &&
                            data?.Meeting?.MeetingRooms[0]?.Location?.Name ? data?.Meeting?.MeetingRooms[0]?.Location?.Name : "- -"}
                          {data?.Meeting && data?.Meeting?.MeetingRooms[0] && data?.Meeting?.MeetingRooms[0]?.Name ? " (" + data?.Meeting?.MeetingRooms[0]?.Name + ")" : " ( - - )"}
                        </span> :
                        <span className="fw-normal ">
                          {data?.Location?.Id && data?.Location?.Name}
                        </span>
                      }
                    </div>
                  </div>

                  {data?.Meeting && data?.Meeting?.Id && (
                    <div className="col-6 txt-md">
                      <span className="icon-sm"><img src={iconUsers} alt="icon-users" /></span>
                      <span>{data?.NumberOfPeople + data?.ExternalAttendeesPeoples + " "}</span>Persons
                    </div>
                  )}

                  {typeof data?.Meeting?.Id === "number" && (
                    <div className="col-6 txt-md d-flex">
                      <span className="icon-sm d-flex align-items-center"><img src={iconClock} alt="icon-clock" /></span>
                      <span className="fw-normal date-time-responsive d-flex align-items-center">
                        <span className="fw-normal">
                          {moment(data?.Meeting?.Start).format("DD-MM-YYYY")}
                        </span>
                        <span className=" fw-normal">
                          {" (" + moment(data?.Meeting?.Start).format("HH:mm")} - {moment(data?.Meeting?.End).format("HH:mm") + ")"}
                        </span>
                      </span>
                    </div>
                  )}

                  <div className="col-6 txt-md">
                    <span className="icon-sm">
                      <img src={iconOrderNumber} alt="icon-order-number" style={{ height: 28, width: 28, padding: "4px 0 0 4px" }} />
                    </span>
                    <span className="fw-normal">{data?.OrderNumber}</span>
                  </div>

                  {!(data?.Meeting && data?.Meeting?.Id) && (
                    <div className="col-6 txt-md d-flex">
                      <span className="icon-sm d-flex align-items-center"><img src={iconClock} alt="icon-clock" /></span>
                      <span className="fw-normal date-time-responsive d-flex align-items-center">
                        <span className="fw-normal">
                          {moment(data?.DeliveryDate).format("DD-MM-YYYY") + "  "}
                        </span>
                        <span className=" fw-normal">
                          ({moment(`${data?.DeliveryHour}:${data?.DeliveryMinute}`, "HH:mm").format("HH:mm")})
                        </span>
                      </span>
                    </div>
                  )}

                  <div className="col-6 txt-md d-flex">
                    <span className="icon-sm"><img src={iconUser} alt="icon-user" /></span>
                    <span className="fw-normal text-nowrap d-flex align-items-center">{data?.OrderedByName}</span>
                  </div>
                </div>

                <div className="row list mt-3">
                  {deliverUpdateStatus && deliverUpdateStatusId === i + 1 && (
                    <div className="col-12 custom-select-details-group d-flex justify-content-between">
                      <Select
                        isMulti
                        defaultValue={updateCanteenUsersDefaultValue}
                        onChange={(e) => setUpdateCanteenUsers(e.map((d) => d.value))}
                        options={AllCanteenUsers?.map((element) => ({ value: element?.Id, label: element?.Name, }))}
                        className="form-control p-0" placeholder="Select Users"
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            marginTop: 2,
                            border: "none",
                            boxShadow: "none",
                          }),
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                          DropdownIndicator: () => null,
                          ClearIndicator: () => null,
                        }}
                      />
                      <button onClick={() => UpdateResponsible(data?.Id)} className="btn btn-secondary btn-icon save-and-close-btn ms-2">
                        <img alt="icon-close" src={iconSave} height={46} width={46} />
                      </button>
                    </div>
                  )}

                  {deliverUpdateStatusId !== i + 1 && (
                    <>
                      {data?.Meeting?.Id ? (
                        <div className="col-7 txt-md d-inline-flex">
                          <div className="icon-md d-inline-block">
                            <img src={iconUsers} className="rounded-circle" alt="Profile" />
                          </div>
                          <div className="userInfo d-inline-block">
                            <span className="txt-sm txt-gray">Delivered by</span>
                            <span>
                              <small>
                                {data?.CanteenUsers && data?.CanteenUsers?.length > 0 ? (
                                  data?.CanteenUsers?.map((deliver, i) => i !== 0 && i !== data?.CanteenUsers?.length ? `, ${deliver?.Name}` : deliver?.Name)
                                ) : (
                                  "- -"
                                )}
                              </small>
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="col-7 txt-md d-inline-flex">
                          <div className="icon-md d-inline-block">
                            <img src={iconUsers} className="rounded-circle" alt="Profile" />
                          </div>
                          <div className="userInfo d-inline-block">
                            <span className="txt-sm txt-gray">Delivered by</span>
                            <span>
                              <small>
                                {data?.CanteenUsers ? (
                                  data?.CanteenUsers?.map((deliver, i) => i !== 0 && i !== data?.CanteenUsers?.length ? `, ${deliver?.Name}` : deliver?.Name)
                                ) : (
                                  "- -"
                                )}
                              </small>
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="col-5 txt-md d-flex justify-content-end order-status-btn-group">
                        {/* Update Order Status Button With Dropdown */}
                        {/* <div className="dropdown order-status-dropdown">
                          <button
                            className="btn btn-secondary btn-icon mt-2 dashboard-order-status-icon-btn overflow-hidden d-flex align-items-center gap-1"
                            id="dropdownMenuButton" data-toggle="dropdown"
                          >
                            {data?.OrderStatus?.Icon && (
                              <img src={data?.OrderStatus?.Icon} className="status-icon" alt={`icon-${data?.OrderStatus?.Name}`} />
                            )}
                            <small className="order-status-text align-middle d-inline-flex">
                              {data?.OrderStatus?.Name ? data?.OrderStatus?.Name : "--"}
                            </small>
                          </button>

                          <div className="dropdown-menu p-0">
                            {AllCateringOrderStatus && AllCateringOrderStatus?.length > 0 && (
                              AllCateringOrderStatus?.map((status) => {
                                const isSelected = data?.OrderStatus?.Id === status?.Id
                                return (
                                  <button
                                    key={status?.Id}
                                    onClick={() => UpdateCateringOrderStatus(data, status?.Id, i)}
                                    className={`dropdown-item${isSelected ? " active" : ""} d-flex align-items-center gap-2`}
                                    style={{ minWidth: 0 }}
                                  >
                                    {status?.Icon && (
                                      <img src={status?.Icon} className="status-icon" alt={`icon-${status?.Name}`} />
                                    )}
                                    <small className="order-status-text align-middle d-inline-flex">
                                      {status?.Name ? status?.Name : "--"}
                                    </small>
                                  </button>
                                )
                              })
                            )}
                          </div>
                        </div> */}

                        {/* Update Order Status Button Without Dropdown */}
                        <button
                          onClick={async () => {
                            // To unfocus button after click
                            document.activeElement.blur()

                            const index = (AllCateringOrderStatus ?? [])?.findIndex((status) => status?.Id === data?.OrderStatus?.Id)
                            const nextStatusId = (AllCateringOrderStatus ?? [])[index + 1]?.Id ?? (AllCateringOrderStatus ?? [])[0]?.Id
                            await UpdateCateringOrderStatus(data, nextStatusId, i)
                          }}
                          className="btn btn-secondary btn-icon mt-2 dashboard-order-status-icon-btn overflow-hidden d-flex align-items-center gap-1"
                        >
                          {data?.OrderStatus?.Icon && (
                            <img src={data?.OrderStatus?.Icon} className="status-icon" alt={`icon-${data?.OrderStatus?.Name}`} />
                          )}
                          <small className="order-status-text align-middle d-inline-flex">
                            {data?.OrderStatus?.Name ? data?.OrderStatus?.Name : "--"}
                          </small>
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </section>
    </>
  )
}
